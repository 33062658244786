import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Empty } from '@h3/antd-vue';
import { TagType, OperateType, SourceType } from '../../../typings/organization/label-manage';
let LabelEmpty = class LabelEmpty extends Vue {
    constructor() {
        super(...arguments);
        this.simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;
        this.TagType = TagType;
        this.OperateType = OperateType;
        this.SourceType = SourceType;
    }
    updateGroup() {
        this.labelLeft.updateGroup(OperateType.Add, this.emptyData);
    }
    updateLabel() {
        this.labelLeft.updateLabel(OperateType.Add, this.emptyData);
    }
};
__decorate([
    Prop({ default: () => { } })
], LabelEmpty.prototype, "emptyData", void 0);
__decorate([
    Prop()
], LabelEmpty.prototype, "labelLeft", void 0);
LabelEmpty = __decorate([
    Component({
        name: 'LabelEmpty',
    })
], LabelEmpty);
export default LabelEmpty;
